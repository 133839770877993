import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import Chevron from "../../images/chevron.inline.svg"
import ddHospitality from "../../images/dd-hospitality.jpg"
import ddMultiFamily from "../../images/dd-mf.jpg"
import ddCommercial from "../../images/dd-commercial.jpg"
import ddReno from "../../images/dd-reno.jpg"
import ddDesign from "../../images/dd-design.jpg"
import ddConstruction from "../../images/dd-construction.jpg"
import ddTrades from "../../images/dd-trades.jpg"


const Container = styled.div`
    background: var(--primary);
    min-height: 48px;
    font-family: "BS";
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    display: flex;
    flex: 1;

`

const Navigation = styled.div`
    font-size: 15px;
    font-weight: 500;
    display: none;

    @media (min-width: 970px) {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        width: 100%;
    }
`
const ProjectsDropdown = styled.div`
    background: #f9f9f9;
    height: 230px;
    width: 100%;
    display: none;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 3px 15px 3px rgb(0 0 0 / 10%);
    padding: 2.5rem 0 2rem;
    position: absolute;
    top: 111px;
    left: 0;
    right: 0;
`

const ServicesDropdown = styled.div`
    background: #f9f9f9;
    height: 230px;
    width: 100%;
    display: none;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 3px 15px 3px rgb(0 0 0 / 10%);
    padding: 2.5rem 0 2rem;
    position: absolute;
    top: 111px;
    left: 0;
    right: 0;
`

const DropdownInner = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  flex: 1;
  padding: 0 1.5rem;

  @media (min-width: 1200px) {
      padding: 0;
  }
`

const ServicesDropdownInner = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  flex: 1;
  padding: 0 1.5rem;

  @media (min-width: 1200px) {
      padding: 0;
  }
`

const MultiFamilyLink = styled(Link)`
`

const HospitalityLink = styled(Link)`

`

const HospitalityLinkBg = styled.div`
    background: url(${ddHospitality});
    background-size: cover;
    background-position: right center;
    filter: brightness(0.8);
    height: 100%;
    width: 100%;
`

const MultiFamilyLinkBg = styled.div`
    background: url(${ddMultiFamily});
    background-position: right center;
    background-size: cover;
    filter: brightness(0.8);
    height: 100%;
    width: 100%;
`
const CommercialLinkBg = styled.div`
    background: url(${ddCommercial});
    background-position: right center;
    background-size: cover;
    filter: brightness(0.8);
    height: 100%;
    width: 100%;
`

const RenovationLinkBg = styled.div`
    background: url(${ddReno});
    background-size: cover;
    background-position: center;
    filter: brightness(0.8);
    height: 100%;
    width: 100%;
`

const CommercialLink = styled(Link)`
`

const RenovationLink = styled(Link)``

const DesignLink = styled(Link)``

const ConstructionLink = styled(Link)``

const TradesLink = styled(Link)``

const DesignLinkBg = styled.div`
    background: url(${ddDesign});
    background-size: cover;
    background-position: center;
    filter: brightness(0.8);
    height: 100%;
    width: 100%;
`

const ConstructionLinkBg = styled.div`
    background: url(${ddConstruction});
    background-size: cover;
    background-position: top center;
    filter: brightness(0.8);
    height: 100%;
    width: 100%;
`

const TradesLinkBg = styled.div`
    background: url(${ddTrades});
    background-size: cover;
    background-position: top center;
    filter: brightness(0.8);
    height: 100%;
    width: 100%;
`



const PrimaryNavigation = () => (
    <Container>
        <Navigation>
        <div className="servicesTrigger">
                <Link to="/services" className="primaryNav">Services<Chevron/></Link>
                <ServicesDropdown className="servicesDropdown">
                    <ServicesDropdownInner>
                        <DesignLink to="/services/development" className="dropDownImageLink">
                            <DesignLinkBg />
                            <span>Development Services</span>
                        </DesignLink>
                        <ConstructionLink to="/services/construction" className="dropDownImageLink">
                            <ConstructionLinkBg />
                            <span>General Construction Services</span>
                        </ConstructionLink>
                        <TradesLink to="/services/subcontracting" className="dropDownImageLink">
                            <TradesLinkBg />
                            <span>Sub Contractor Services</span>
                        </TradesLink>
                    </ServicesDropdownInner>
                </ServicesDropdown>
            </div>
            <div className="projectTrigger">
                <Link to="/projects" className="primaryNav">Projects<Chevron/></Link>
                <ProjectsDropdown className="projectsDropdown">
                    <DropdownInner>
                        <MultiFamilyLink to="/projects/multifamily" className="dropDownImageLink">
                            <MultiFamilyLinkBg />
                            <span>Multi-family Projects</span>
                        </MultiFamilyLink>
                        <HospitalityLink to="/projects/hospitality" className="dropDownImageLink">
                            <HospitalityLinkBg />
                            <span>Hospitality Projects</span>
                        </HospitalityLink>
                        <CommercialLink to="/projects/commercial" className="dropDownImageLink">
                            <CommercialLinkBg />
                            <span>Commercial Projects</span>
                        </CommercialLink>
                        <RenovationLink to="/projects/renovations" className="dropDownImageLink">
                            <RenovationLinkBg />
                            <span>Renovation Projects</span>
                        </RenovationLink>
                    </DropdownInner>
                </ProjectsDropdown>
            </div>
            <Link to="/about" className="primaryNav">About</Link>
            <Link to="/careers" className="primaryNav">Careers</Link>
            <Link to="/media" className="primaryNav">Media</Link>
            <Link to="/contact" className="primaryNav">Contact</Link>
        </Navigation>
    </Container>
)

export default PrimaryNavigation
